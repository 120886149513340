import { SdkConfig } from '../../config';
import { isNonEmptyString } from '../../utils';

import { ICustomerAddress, ITokenizedCard, LoggerFactory, UnknownObject } from './deps';

import AuthNet from './auth-net';
import OneInc from './one-inc';
import Stripe from './stripe';
import Cybersource from './cybersource';
/**
 * Error message to use when rejecting card collection because the user cancelled.
 * Necessary because standard JavaScript promises are binary (resolve/reject) and
 * do not support cancellation.
 */
export const CANCEL_FROM_VENDOR_FORM = 'CANCEL_FROM_VENDOR_FORM';

export interface IInjections {
  folder: string;
  loadScript: (url: string, id?: string) => Promise<{}>;
  loggerFactory: LoggerFactory;
}

// used at runtime to validate the 'vendor' returned by vendor credentials
export const validProviderIds = ['AUTHORIZE', 'ONEINC', 'STRIPE', 'CYBERSOURCE'] as const;

// used at compile time to validate string literals that must be a valid vendor
export type ProviderId = typeof validProviderIds[number];

export interface IProviderData {
  applicationId: string | null;
  isSandbox: boolean;
  sdkUrl: string;
  vendor: ProviderId;
  vendorKey: string;
}

/**
 * Base implementation for a payment vendor (e.g. One Inc).
 */
export abstract class AbstractPaymentProvider {
  public readonly vendor: ProviderId;

  protected readonly applicationId: string | null;
  protected readonly isSandbox: boolean;
  protected readonly sdkUrl: string;
  protected readonly vendorKey: string;

  protected readonly domRoot: HTMLDivElement;
  protected readonly injections: IInjections;
  protected readonly sdkConfig: SdkConfig;

  /**
   * The constructor will be called to initialize the specific payment provider (e.g. Stripe) based
   * on the vendor indicated in the vendor credentials endpoint.
   *
   * @param vendorCredentials raw response from vendor credentials endpoint
   * @param injections services that are passed through constructor for making testing easier
   * @param sdkConfig the config passed from the web app including the Midas JS SDK
   */
  constructor(vendorCredentials: UnknownObject, injections: IInjections, sdkConfig: SdkConfig) {
    const { applicationId, isSandbox, vendor, vendorKey } = vendorCredentials;

    if (!isNonEmptyString(vendorKey)) {
      throw new Error(`provider ${vendor} is missing vendorKey`);
    }

    // typed values from vendor credentials
    this.applicationId = typeof applicationId === 'string' ? applicationId : null;
    this.isSandbox = Boolean(isSandbox);
    this.vendor = vendor as ProviderId; // runtime check in switch stmt at request-provider.ts > createProvider
    this.vendorKey = vendorKey as string; // runtime check above

    // other constructor deps
    this.injections = injections;
    this.sdkConfig = sdkConfig;

    this.domRoot = this.addDomRoot();
    this.sdkUrl = this.getSdkUrl(this.isSandbox);
  }

  /**
   * This method can be overriden to provide vendor specific address formatting to an address
   * used in: prefilling the custom collection form, prefilling the vendor collection form, and
   * the "add payment source" api call.
   *
   * Example: One Inc does not accept zip+4.
   *
   * @param address address provided by the calling application or the custom collection form
   * @returns cleaned address
   */
  public cleanAddress(address: ICustomerAddress): ICustomerAddress {
    return address;
  }

  /**
   * This method utilizes the vendor's client side SDK to collect a tokenized credit card.
   *
   * Note: must reject with a specific error message if the user closes the vendor form.
   * `error.message === CANCEL_FROM_VENDOR_FORM`
   *
   * @param addressToPrefill optional address to prefill in the vendor form (if supported by
   * the vendor)
   * @returns promise for the tokenized card data
   */
  public abstract collectTokenizedCard(
    addressToPrefill?: ICustomerAddress,
  ): Promise<ITokenizedCard>;

  /**
   * Returns the data associated with the payment provider; used for debugging.
   *
   * @returns vendor data
   */
  public serialize(): IProviderData {
    return {
      applicationId: this.applicationId,
      isSandbox: this.isSandbox,
      sdkUrl: this.sdkUrl,
      vendor: this.vendor,
      vendorKey: this.vendorKey,
    };
  }

  /**
   * Gets a script tag source for the CDN version of a vendor's client side SDK. Some
   * vendors will urls that differ by the sandbox flag.
   *
   * @param isSandbox flag indicating whether to use the preproduction vendor SDK
   * @returns url for the vendor's client side SDK
   */
  protected abstract getSdkUrl(isSandbox: boolean): string;

  /**
   * This takes a numeric value for the year and the month. This also pads the value for the month.
   * @param year in YYYY format e.g. 2020
   * @param month
   * @returns date as YYYY-MM string expected by api
   */
  protected static formatExpirationDate(year: number, month: number) {
    const monthStr = month < 10 ? `0${month}` : month;
    return `${year}-${monthStr}`;
  }

  /**
   * Used in base constructor to create a DOM element where vendor specific UI can be inserted.
   */
  private addDomRoot(): HTMLDivElement {
    const div = document.createElement('div');
    div.id = 'gw-wps__vendor-root';
    document.body.appendChild(div);
    return div;
  }
}

export type PaymentProvider = Readonly<AuthNet | OneInc | Stripe | Cybersource>;
