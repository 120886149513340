import verifyEnvironmentVars from './verify-env-vars';

verifyEnvironmentVars();

export const PRODUCTION_PAYMENTS_API = process.env.PRODUCTION_PAYMENTS_API as string;
export const PRODUCTION_SDK_FOLDER = process.env.PRODUCTION_SDK_FOLDER as string;

export const AUTH_NET_SANDBOX_SDK = 'https://jstest.authorize.net/v3/AcceptUI.js';
export const AUTH_NET_PROD_SDK = 'https://js.authorize.net/v3/AcceptUI.js';

// This prod URL is not in the docs but the vendor confirmed it. See: INS-3540
export const ONE_INC_PROD_SDK =
  'https://portalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js';
export const ONE_INC_SANDBOX_SDK =
  'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js';

// These selectors cannot be private to the JS that uses them
//  because the markup is static (collection-iframe/static/collection-iframe.html)
export const ACTIVITY_INDICATOR_SELECTOR = '[data-id="gw-wps_activity-indicator"]';
export const ACTIVITY_INDICATOR_SPINNER_SELECTOR = '[data-id="gw-wps_activity-indicator_spinner"]';
export const ACTIVITY_INDICATOR_TEXT_SELECTOR = '[data-id="gw-wps_activity-indicator_text"]';
export const CLOSE_BUTTON_SELECTOR = '[data-id="gw-wps_close-button"]';
export const CUSTOM_ADDRESS_FORM_SELECTOR = '[data-id="gw-wps_card-billing-address"]';
export const CUSTOM_ADDRESS_CLOSE_BUTTON_SELECTOR =
  '[data-id="gw-wps_card-billing-address_close-button"]';

export const CUSTOM_ADDRESS_HEADER_SELECTOR = '[data-id="gw-wps_card-billing-address_header"]';
export const CUSTOM_ADDRESS_SUBMIT_BUTTON_SELECTOR =
  '[data-id="gw-wps_card-billing-address_submit-button"]';
export const CYBERSOURCE_SANDBOX_SDK =
  'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js';
export const CYBERSOURCE_PROD_SDK =
  'https://flex.cybersource.com/microform/bundle/v2/flex-microform.min.js';
